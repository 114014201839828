html, body {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent page scroll */
}

.data-grid-container {
  width: 100%;
  max-height: 100%; /* Constrain the height of the container */
  display: flex;
  flex-direction: column;
}

.data-grid-scroll {
  flex-grow: 1;
  max-height: 50vh; /* Restrict the height of the table, adjust as needed */
  overflow-y: auto; /* Enable scroll within the table */
  position: relative; /* Ensure the scroll area is positioned correctly */
}

.data-grid-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .data-grid-table th, .data-grid-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .data-grid-table th {
    background-color: #EAECF0FF;
    font-weight: bold;
    position: sticky; /* Make the header sticky */
    top: 0; /* Stick to the top */

  }

.data-grid-actions {
  margin-top: 0.5rem; /* Ensure buttons are positioned below the table */
  text-align: right; /* Align buttons to the right */
  visibility: visible; /* Ensure buttons are visible */
}


.edit-button {
  background-color: transparent;
  border: none;
  color: blue;
  cursor: pointer;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ddd;
}

.pagination-controls button {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination-controls select {
  padding: 5px;
}

.data-grid-table tbody tr.edited-row {
  background-color: #c2dbff; /* Background for edited row */
  }